import { Image } from "@/Components/common/Image"
import { Button } from "@/Components/Control/Button"
import { CtrlReCaptcha } from "@/Components/ctrl/ReCaptcha"
import { FormWrapper } from "@/Components/Form"
import { Input } from "@/Components/Form/Input"
import { Msg } from "@/Components/Msg"
import { COMPANY_EMAIL_ANCHOR } from "@/constants/CommonConstants"
import { Layout } from "@/Layout"
import { Phones } from "@/Layout/Footer/components/Phones"
import { FormPhoneInput } from "@/NewComponents/PhoneInput"
import { ThemeMode } from "@/types"
import {
  useContactFormV2,
  VALID_EMAIL,
  VALID_NAME,
  VALID_PHONE,
} from "@usher/pe-client-front-end-lib"
import { useIntl } from "react-intl"
import { AgreeTermCheck } from "../Auth/Register/Checkboxes"
import "./style.css"

export const ContactUs = () => {
  const { onSubmit, formMethods, captchaRef, onCaptchaChange } = useContactFormV2()
  const { formatMessage } = useIntl()
  return (
    <Layout>
      <section className="contact">
        <div className="container">
          <div className="contact__wrap">
            <div className="contact__box-title">
              <h1 className="contact-title">
                <Msg id="footer4" />
              </h1>
              <div className="contact-subtitle">
                <Msg id="submit_a_ticket" />
              </div>
            </div>
            <div className="contact__content">
              <div className="contact-form">
                <FormWrapper methods={formMethods} onSubmit={onSubmit}>
                  <div className="contact-form__box-label">
                    <Input
                      name="name"
                      required
                      autoFocus
                      rules={VALID_NAME}
                      label={formatMessage({ id: "fname" })}
                    />
                  </div>
                  <div className="contact-form__box-label">
                    <FormPhoneInput
                      label={formatMessage({ id: "phone" })}
                      firstCountries={["US", "GB"]}
                      defaultCountry="US"
                      name="phone"
                      required
                      rules={{ ...VALID_PHONE, minLength: undefined, maxLength: undefined }}
                    />
                  </div>

                  <div className="contact-form__box-label">
                    <Input
                      name="email"
                      isEmail
                      label={formatMessage({ id: "email_add" })}
                      rules={VALID_EMAIL}
                      required
                    />
                  </div>

                  <div className="contact-form__box-label">
                    <Input name="subjec" label={formatMessage({ id: "subject" })} required />
                  </div>

                  <div className="contact-form__box-label">
                    <Input
                      multiline
                      rows={7}
                      name="message"
                      label={formatMessage({ id: "message" })}
                      required
                    />
                  </div>

                  <div className="singup-register">
                    <AgreeTermCheck onlyPrivacy />
                  </div>

                  <div className="captcha">
                    <CtrlReCaptcha refObj={captchaRef as never} onChange={onCaptchaChange} />
                  </div>
                  <Button variant="primary" type="submit">
                    <Msg id="send" />
                  </Button>
                </FormWrapper>
              </div>

              <div className="contacts">
                <div className="contacts__box">
                  <h4 className="contacts__title">
                    <Msg id="footer4" />
                  </h4>
                  <Phones themeMode={ThemeMode.Dark} />
                  <div className="contacts__email">
                    <span>
                      <Msg id="email_add" />
                    </span>
                    {COMPANY_EMAIL_ANCHOR}
                  </div>
                </div>

                <div className="contacts__img">
                  <Image src="planet-cont.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
