import { Image } from "@/Components/common/Image"
import { Button } from "@/Components/Control/Button"
import { Msg } from "@/Components/Msg"
import { Footer } from "@/Layout/Footer"
import { Header } from "@/Layout/Header"
import { BSelect } from "@/NewComponents/BSelect"
import { IOption, ThemeMode } from "@/types"
import { useAvailableAssets } from "@usher/pe-client-front-end-lib"
import { useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import { AssetRow } from "./AssetRow"
import "./style.css"
export const AboutUs = () => {
  const history = useHistory()
  const { options, ratesCrypto, ratesFiat, setCrypto } = useAvailableAssets()
  const allOptions = useMemo(
    () => ({
      fiat: options.fiat
        .filter((fiat) => fiat !== "USD")
        .map((fiat) => ({ label: fiat, value: fiat })),
      rates: options.crypto.map((crypto) => ({ label: crypto, value: crypto })),
    }),
    [options],
  )
  const [selectedCrypto, setSelectedCrypto] = useState<IOption | null>(allOptions.rates[0])
  return (
    <div className="about-us-wrapper">
      <Header
        themeMode={ThemeMode.Dark}
        forAboutPage
        additionalContent={
          <h1 className="h2 about-title">
            <Msg id="about1" />
          </h1>
        }
      />
      <main className="main about-us-main">
        <section className="about">
          <div className="container">
            <div className="about__wrap">
              <ul className="about-list">
                <li className="about-list__item">
                  <Image src="ab-sale.svg" />
                  <h3 className="about-list__title">
                    <Msg id="about30" />
                  </h3>
                </li>
                <li className="about-list__item">
                  <Image src="ab-api.svg" />
                  <h3 className="about-list__title">API</h3>
                </li>
                <li className="about-list__item">
                  <Image src="ab-exchange.svg" />
                  <h3 className="about-list__title">
                    <Msg id="about31" />
                  </h3>
                </li>
                <li className="about-list__item">
                  <Image src="ab-supp.svg" />
                  <h3 className="about-list__title">
                    <Msg id="about32" />
                  </h3>
                </li>
                <li className="about-list__item">
                  <Image src="ab-prog.svg" />
                  <h3 className="about-list__title">
                    <Msg id="about33" />
                  </h3>
                </li>
              </ul>
              <div className="about__text">
                <Msg id="about2" />
              </div>
              <div className="about__text">
                <Msg id="about3" />
              </div>
              <div className="about__text">
                <Msg id="about4" />
              </div>
              <div className="about__text">
                <Msg id="about5" />
              </div>
            </div>
          </div>
        </section>

        <section className="avail">
          <div className="container">
            <div className="avail__wrap">
              <h2 className="h2 avail__title">
                <Msg id="about6" />
              </h2>
              <div className="avail__content">
                <div className="avail-item">
                  <div className="avail-form">
                    <h3 className="avail-form__title">
                      <Msg id="about34" />
                    </h3>

                    <BSelect
                      buttonStyle={{ minWidth: 150 }}
                      options={allOptions.rates}
                      value={allOptions.rates[0]}
                      onSelect={(option) => {
                        setCrypto(option.value)
                        setSelectedCrypto(option)
                      }}
                    />
                  </div>
                  <ul className="avail-item__list">
                    {ratesCrypto
                      .filter((rate) => rate.symbol !== "USD")
                      .map((val) => (
                        <li className="avail-item__item">
                          <div className="avail-item__box">
                            <Image className="avail-item__img" src="eur.svg" alt="" />
                            <div className="avail-item__block">
                              <span>1</span>
                              <p>EUR</p>
                            </div>
                          </div>
                          <Image src="leftRight.svg" />
                          <div className="avail-item__box">
                            <Image
                              className="avail-item__img"
                              src={`${(selectedCrypto ?? allOptions.rates?.[0])?.label
                                .split("-")[0]
                                .toLowerCase()}.svg`}
                            />
                            <div className="avail-item__block">
                              <span>{val.rate}</span>
                              <p>{selectedCrypto?.label ?? allOptions.rates?.[0]?.label}</p>
                            </div>
                          </div>
                        </li>
                      ))}
                    <li className="avail-item__item">
                      <div className="avail-item__box">
                        <Image className="avail-item__img" src="sub.svg" />
                        <Image className="avail-item__img" src="svg.svg" />
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="58"
                        height="47"
                        viewBox="0 0 58 47"
                        fill="none"
                      >
                        <path
                          d="M22 23.25C21.5858 23.25 21.25 23.5858 21.25 24C21.25 24.4142 21.5858 24.75 22 24.75V23.25ZM37.0303 24.5303C37.3232 24.2374 37.3232 23.7626 37.0303 23.4697L32.2574 18.6967C31.9645 18.4038 31.4896 18.4038 31.1967 18.6967C30.9038 18.9896 30.9038 19.4645 31.1967 19.7574L35.4393 24L31.1967 28.2426C30.9038 28.5355 30.9038 29.0104 31.1967 29.3033C31.4896 29.5962 31.9645 29.5962 32.2574 29.3033L37.0303 24.5303ZM22 24.75L36.5 24.75V23.25L22 23.25V24.75Z"
                          fill="#B7B7B7"
                        />
                      </svg>
                      <div className="avail-item__box-soon">
                        <div className="avail-item__soon">
                          <Msg id="coming_soon" />
                          ...
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="avail__item blue-avail">
                  <div className="avail-form">
                    <h3 className="avail-form__title">
                      <Msg id="about35" />
                    </h3>
                    <form action="#">
                      <div className="exhange-field-about exhange-field-about__btc">
                        <BSelect
                          buttonStyle={{ minWidth: 100 }}
                          options={allOptions.fiat}
                          value={allOptions.fiat[0]}
                        />
                      </div>
                    </form>
                  </div>
                  <table className="avail-item__list">
                    {ratesFiat
                      .filter((fiat) => fiat.symbol !== "BCH")
                      .map((fiat) => (
                        <AssetRow to={fiat.rate} from={1} label={fiat.symbol} suffix="EUR" />
                      ))}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="benef">
          <div className="container">
            <div className="benef__wrap">
              <h2 className="h2 benef__title">
                <Msg id="about7" />
              </h2>
              <ul className="benef-list">
                <li className="benef-list__item">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    fill="none"
                  >
                    <path
                      d="M41.3333 18.75H38.1667V12.5C38.1667 5.59687 32.4952 0 25.5 0C18.5048 0 12.8333 5.59687 12.8333 12.5V18.75H9.66667C7.91629 18.75 6.5 20.1477 6.5 21.875V46.875C6.5 48.6023 7.91629 50 9.66667 50H41.3333C43.0837 50 44.5 48.6023 44.5 46.875V21.875C44.5 20.1477 43.0837 18.75 41.3333 18.75ZM25.5 37.5C23.7496 37.5 22.3333 36.1023 22.3333 34.375C22.3333 32.6477 23.7496 31.25 25.5 31.25C27.2504 31.25 28.6667 32.6477 28.6667 34.375C28.6667 36.1023 27.2504 37.5 25.5 37.5ZM31.8333 18.75H19.1667V12.5C19.1667 9.04844 22.0024 6.25 25.5 6.25C28.9976 6.25 31.8333 9.04844 31.8333 12.5V18.75Z"
                      fill="black"
                    />
                  </svg>
                  <h3 className="benef-list__title">
                    <Msg id="about8" />
                  </h3>
                  <p className="benef-list__text">
                    <Msg id="about9" />
                  </p>
                </li>
                <li className="benef-list__item">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_1367_24029)">
                      <path
                        d="M7.52447 35.4635C7.67415 35.0029 8.32585 35.0029 8.47553 35.4635L9.68386 39.1824C9.75079 39.3884 9.94277 39.5279 10.1594 39.5279H14.0696C14.554 39.5279 14.7554 40.1477 14.3635 40.4324L11.2001 42.7307C11.0248 42.8581 10.9515 43.0838 11.0184 43.2898L12.2268 47.0086C12.3764 47.4693 11.8492 47.8523 11.4573 47.5676L8.29389 45.2693C8.11865 45.1419 7.88135 45.1419 7.70611 45.2693L4.54267 47.5676C4.15081 47.8523 3.62357 47.4693 3.77325 47.0086L4.98157 43.2898C5.04851 43.0838 4.97518 42.8581 4.79994 42.7307L1.6365 40.4324C1.24464 40.1477 1.44603 39.5279 1.93039 39.5279H5.84062C6.05723 39.5279 6.24921 39.3884 6.31614 39.1824L7.52447 35.4635Z"
                        fill="black"
                      />
                      <path
                        d="M7.52447 18.4635C7.67415 18.0029 8.32585 18.0029 8.47553 18.4635L9.68386 22.1824C9.75079 22.3884 9.94277 22.5279 10.1594 22.5279H14.0696C14.554 22.5279 14.7554 23.1477 14.3635 23.4324L11.2001 25.7307C11.0248 25.8581 10.9515 26.0838 11.0184 26.2898L12.2268 30.0086C12.3764 30.4693 11.8492 30.8523 11.4573 30.5676L8.29389 28.2693C8.11865 28.1419 7.88135 28.1419 7.70611 28.2693L4.54267 30.5676C4.15081 30.8523 3.62357 30.4693 3.77325 30.0086L4.98157 26.2898C5.04851 26.0838 4.97518 25.8581 4.79994 25.7307L1.6365 23.4324C1.24464 23.1477 1.44603 22.5279 1.93039 22.5279H5.84062C6.05723 22.5279 6.24921 22.3884 6.31614 22.1824L7.52447 18.4635Z"
                        fill="black"
                      />
                      <path
                        d="M7.52447 1.46352C7.67415 1.00287 8.32585 1.00287 8.47553 1.46353L9.68386 5.18237C9.75079 5.38838 9.94277 5.52786 10.1594 5.52786H14.0696C14.554 5.52786 14.7554 6.14767 14.3635 6.43237L11.2001 8.73075C11.0248 8.85807 10.9515 9.08375 11.0184 9.28976L12.2268 13.0086C12.3764 13.4693 11.8492 13.8523 11.4573 13.5676L8.29389 11.2693C8.11865 11.1419 7.88135 11.1419 7.70611 11.2693L4.54267 13.5676C4.15081 13.8523 3.62357 13.4693 3.77325 13.0086L4.98157 9.28976C5.04851 9.08375 4.97518 8.85807 4.79994 8.73075L1.6365 6.43237C1.24464 6.14767 1.44603 5.52786 1.93039 5.52786H5.84062C6.05723 5.52786 6.24921 5.38838 6.31614 5.18237L7.52447 1.46352Z"
                        fill="black"
                      />
                      <path
                        d="M19 0H48C49.1046 0 50 0.895431 50 2V14C50 15.1046 49.1046 16 48 16H19V0Z"
                        fill="black"
                      />
                      <path
                        d="M19 17H32.5C33.6046 17 34.5 17.8954 34.5 19V31C34.5 32.1046 33.6046 33 32.5 33H19V17Z"
                        fill="black"
                      />
                      <path
                        d="M19 34H24.75C25.8546 34 26.75 34.8954 26.75 36V48C26.75 49.1046 25.8546 50 24.75 50H19V34Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1367_24029">
                        <rect width="50" height="50" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <h3 className="benef-list__title">
                    <Msg id="about10" />
                  </h3>
                  <p className="benef-list__text">
                    <Msg id="about11" />
                  </p>
                </li>
                <li className="benef-list__item">
                  <svg
                    width="44"
                    height="50"
                    viewBox="0 0 44 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.125 29.7188C0.125 31.1692 0.70117 32.5602 1.72676 33.5857C2.75235 34.6113 4.14335 35.1875 5.59375 35.1875H38.4062C39.8567 35.1875 41.2477 34.6113 42.2732 33.5857C43.2988 32.5602 43.875 31.1692 43.875 29.7188V14.6797H0.125V29.7188ZM6.57031 22.2969C6.57031 21.5199 6.87898 20.7747 7.4284 20.2253C7.97782 19.6759 8.723 19.3672 9.5 19.3672H14.1875C14.9645 19.3672 15.7097 19.6759 16.2591 20.2253C16.8085 20.7747 17.1172 21.5199 17.1172 22.2969V24.25C17.1172 25.027 16.8085 25.7722 16.2591 26.3216C15.7097 26.871 14.9645 27.1797 14.1875 27.1797H9.5C8.723 27.1797 7.97782 26.871 7.4284 26.3216C6.87898 25.7722 6.57031 25.027 6.57031 24.25V22.2969ZM38.4062 0.8125H5.59375C4.14335 0.8125 2.75235 1.38867 1.72676 2.41426C0.70117 3.43985 0.125 4.83085 0.125 6.28125V8.82031H43.875V6.28125C43.875 4.83085 43.2988 3.43985 42.2732 2.41426C41.2477 1.38867 39.8567 0.8125 38.4062 0.8125Z"
                      fill="black"
                    />
                  </svg>
                  <h3 className="benef-list__title">
                    <Msg id="about12" />
                  </h3>
                  <p className="benef-list__text">
                    <Msg id="about13" />
                  </p>{" "}
                </li>
                <li className="benef-list__item">
                  <svg
                    width="51"
                    height="50"
                    viewBox="0 0 51 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="25.5" cy="25.5" r="23.5" fill="black" />
                    <path
                      d="M11.9332 31.36C12.3652 31.104 12.8692 30.784 13.4452 30.4C14.0372 30 14.6452 29.56 15.2692 29.08C15.9092 28.584 16.5412 28.056 17.1652 27.496C17.7892 26.92 18.3412 26.336 18.8212 25.744C19.3172 25.152 19.7172 24.56 20.0212 23.968C20.3252 23.376 20.4772 22.8 20.4772 22.24C20.4772 21.568 20.2692 21.048 19.8532 20.68C19.4372 20.296 18.8852 20.104 18.1972 20.104C17.4772 20.104 16.8452 20.336 16.3012 20.8C15.7732 21.248 15.4292 21.976 15.2692 22.984L11.7652 22.336C11.8772 21.472 12.1092 20.704 12.4612 20.032C12.8292 19.36 13.2852 18.792 13.8292 18.328C14.3892 17.848 15.0292 17.488 15.7492 17.248C16.4692 16.992 17.2612 16.864 18.1252 16.864C18.9892 16.864 19.7892 16.992 20.5252 17.248C21.2612 17.504 21.8932 17.864 22.4212 18.328C22.9652 18.776 23.3812 19.32 23.6692 19.96C23.9732 20.6 24.1252 21.304 24.1252 22.072C24.1252 22.984 23.9092 23.864 23.4772 24.712C23.0452 25.56 22.5012 26.368 21.8452 27.136C21.1892 27.888 20.4612 28.584 19.6612 29.224C18.8772 29.848 18.1332 30.392 17.4292 30.856H24.4852V34H11.9332V31.36ZM33.8482 31.096H25.5202V28.456L32.5762 17.2H37.2562V28.144H39.1762V31.096H37.2562V34H33.8482V31.096ZM33.8482 28.144V20.632H33.5602L29.0002 27.832V28.144H33.8482Z"
                      fill="white"
                    />
                  </svg>
                  <h3 className="benef-list__title">
                    <Msg id="about14" />
                  </h3>
                  <p className="benef-list__text">
                    <Msg id="about15" />
                  </p>
                </li>
                <li className="benef-list__item">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="51"
                    height="50"
                    viewBox="0 0 51 50"
                    fill="none"
                  >
                    <path
                      d="M45.9456 32.3419C43.2263 32.5078 39.6499 35.1952 36.2002 36.7408C31.9726 38.632 25.3906 37.1097 25.39 37.1097C26.4052 36.6409 30.5309 35.6686 31.3616 35.3056C35.7783 33.3852 35.4048 29.3782 33.3012 29.4118C30.5214 29.4579 28.8905 30.1327 23.3459 30.8805C19.1429 31.4461 14.1718 31.2393 11.7875 32.1397C8.42165 33.4113 3 41.9676 3 41.9676L11.3813 50C11.3813 50 16.57 44.9472 19.0944 44.9472C24.848 44.9472 25.0814 44.8687 30.4236 44.5835C32.694 44.4632 33.1684 44.3711 34.4687 43.9355C41.3931 41.6218 48.8285 35.4544 48.965 34.7123C49.2817 32.9941 47.3825 32.2535 45.9456 32.3419Z"
                      fill="black"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M29.3489 26.7501C36.8167 26.7501 42.8705 20.7619 42.8705 13.375C42.8705 5.98821 36.8167 0 29.3489 0C21.8811 0 15.8273 5.98821 15.8273 13.375C15.8273 20.7619 21.8811 26.7501 29.3489 26.7501ZM23.3672 16.4583C22.5529 17.2637 22.5529 18.5696 23.3672 19.3751C24.1815 20.1806 25.5017 20.1806 26.316 19.3751L35.4147 10.3751C36.2289 9.56961 36.2289 8.2637 35.4147 7.45824C34.6004 6.65278 33.2802 6.65278 32.4659 7.45824L23.3672 16.4583ZM26.8847 8.90628C26.8847 10.0281 25.9653 10.9375 24.8312 10.9375C23.697 10.9375 22.7776 10.0281 22.7776 8.90628C22.7776 7.78445 23.697 6.87502 24.8312 6.87502C25.9653 6.87502 26.8847 7.78445 26.8847 8.90628ZM33.9298 19.9376C35.064 19.9376 35.9834 19.0281 35.9834 17.9063C35.9834 16.7845 35.064 15.8751 33.9298 15.8751C32.7957 15.8751 31.8763 16.7845 31.8763 17.9063C31.8763 19.0281 32.7957 19.9376 33.9298 19.9376Z"
                      fill="black"
                    />
                  </svg>
                  <h3 className="benef-list__title">
                    <Msg id="about16" />
                  </h3>
                  <p className="benef-list__text benef-list__var">
                    <Msg id="about17" />
                  </p>
                </li>
                <li className="benef-list__item">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="51"
                    height="50"
                    viewBox="0 0 51 50"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19.1805 3.32749C22.6872 0.339109 27.7837 0.228428 31.4081 2.99544L31.8196 3.32749L32.7367 4.10893C33.4054 4.67886 34.2137 5.05552 35.0745 5.20286L35.5088 5.25725L36.7099 5.35307C41.3147 5.72054 45.004 9.26378 45.5956 13.8005L45.647 14.2902L45.7428 15.4912C45.8127 16.3671 46.118 17.205 46.6225 17.9178L46.8912 18.2635L47.6726 19.1804C50.6609 22.6871 50.7715 27.7836 48.0047 31.408L47.6726 31.8195L46.8912 32.7365C46.3213 33.4053 45.9445 34.2136 45.7972 35.0743L45.7428 35.5087L45.647 36.7098C45.2794 41.3146 41.7363 45.004 37.1997 45.5954L36.7099 45.6469L35.5088 45.7428C34.633 45.8126 33.7951 46.1178 33.0823 46.6223L32.7367 46.891L31.8196 47.6724C28.313 50.6609 23.2165 50.7716 19.592 48.0045L19.1805 47.6724L18.2635 46.891C17.5947 46.3211 16.7865 45.9444 15.9256 45.7971L15.4912 45.7428L14.2903 45.6469C9.6854 45.2795 5.99603 41.7362 5.40451 37.1995L5.35308 36.7098L5.25724 35.5087C5.18734 34.6329 4.88213 33.795 4.37763 33.0821L4.10894 32.7365L3.32749 31.8195C0.339107 28.3129 0.228426 23.2164 2.99545 19.5919L3.32749 19.1804L4.10894 18.2635C4.67887 17.5947 5.05551 16.7864 5.20284 15.9256L5.25724 15.4912L5.35308 14.2902C5.72055 9.68538 9.2638 5.99602 13.8005 5.4045L14.2903 5.35307L15.4912 5.25725C16.3672 5.18735 17.205 4.88212 17.9179 4.37762L18.2635 4.10893L19.1805 3.32749ZM31.5894 27.9359C29.5716 27.9359 27.9358 29.5718 27.9358 31.5895C27.9358 33.6073 29.5716 35.2432 31.5894 35.2432C33.6072 35.2432 35.2431 33.6073 35.2431 31.5895C35.2431 29.5718 33.6072 27.9359 31.5894 27.9359ZM31.085 16.4705L16.4704 31.0851C15.5191 32.0363 15.5191 33.5786 16.4704 34.5298C17.4216 35.4809 18.9638 35.4809 19.9151 34.5298L34.5297 19.9152C35.4808 18.9639 35.4808 17.4217 34.5297 16.4705C33.5785 15.5192 32.0362 15.5192 31.085 16.4705ZM19.4106 15.757C17.3927 15.757 15.7569 17.3928 15.7569 19.4107C15.7569 21.4285 17.3927 23.0644 19.4106 23.0644C21.4284 23.0644 23.0642 21.4285 23.0642 19.4107C23.0642 17.3928 21.4284 15.757 19.4106 15.757Z"
                      fill="black"
                    />
                  </svg>
                  <h3 className="benef-list__title">
                    <Msg id="about18" />
                  </h3>
                  <p className="benef-list__text">
                    <Msg id="about19" />
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="safe">
          <div className="container">
            <div className="safe__wrap">
              <h2 className="h2 safe__title">
                <Msg id="about20" />
                🔒
              </h2>
              <div className="safe__slogan">
                <div className="safe__item">
                  <Msg id="about21" />
                </div>
                <div className="safe__item">
                  <Msg id="about22" />
                </div>
              </div>
              <ul className="safe-list">
                <li className="safe-list__item">
                  <div className="safe-list__block">
                    <h3 className="safe-list__title">
                      <Msg id="about23" />
                    </h3>
                    <p className="safe-list__text">
                      <Msg id="about24" />
                    </p>
                  </div>
                </li>
                <li className="safe-list__item">
                  <div className="safe-list__block">
                    <h3 className="safe-list__title">
                      <Msg id="about25" />
                    </h3>
                    <p className="safe-list__text">
                      <Msg id="about26" />
                    </p>
                  </div>
                </li>
                <li className="safe-list__item">
                  <div className="safe-list__block">
                    <h3 className="safe-list__title">
                      <Msg id="about27" />
                    </h3>
                    <p className="safe-list__text">
                      <Msg id="about28" />
                    </p>
                  </div>
                </li>
                <li>
                  <Button
                    className="safe-btn"
                    minWidth={232}
                    onClick={() => history.push("/register")}
                  >
                    <Msg id="about29" />
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </main>
      <Footer themeMode={ThemeMode.Light} />
    </div>
  )
}
