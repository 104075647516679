import { Msg } from "@/Components/Msg"
import { useIntl } from "react-intl"
import { ExchangeBlock } from "../HeroScreen/ExchangeBlock"
import "./style.css"

interface IPaymentsProps {
  isLight?: boolean
}

export const Payments = ({ isLight }: IPaymentsProps) => {
  const translate = useIntl()
  const translate_text = translate.formatMessage({ id: "estep4" })
  return (
    <section className={`payment${isLight ? " light-paymentt" : ""}`}>
      <div className="container">
        <h2 className="h2 payment__h2">
          <Msg id="buy2" /> <Msg id="right_now" />
          <span>
            <Msg id="using_any" /> {translate_text === "estep4" ? "" : translate_text}
          </span>{" "}
          <Msg id="buy4" />
        </h2>
        <span className="h2 h2-mob">
          <Msg id="buy2" />{" "}
          <span>
            <Msg id="right_now" />
          </span>{" "}
          <Msg id="using_any" />
          <span>
            <Msg id="estep4" />
          </span>{" "}
          <Msg id="buy4" />
        </span>
        <div className="payment__form">
          <form action="#">
            <ExchangeBlock isLight={isLight} />
          </form>
        </div>
      </div>
    </section>
  )
}
