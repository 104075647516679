export const FAQ_DATA: { title: string; content: string }[] = [
  {
    title: "ffaq2",
    content: "ffaq3",
  },
  {
    title: "ffaq4",
    content: "ffaq5",
  },
  {
    title: "ffaq6",
    content: "ffaq7",
  },
  {
    title: "ffaq8",
    content: "ffaq9",
  },
  {
    title: "ffaq10",
    content: "ffaq11",
  },
  {
    title: "faq12",
    content: "faq13",
  },
]
