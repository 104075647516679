export const LEGAL_LINKS: string[][] = [
  ["amlkyc", "aml-policy"],
  ["footer8", "terms-of-use"],
  ["privacy_title", "privacy-policy"],
  ["refund_title", "refund-policy"],
  ["complaints", "complaints", "complaints"],
  ["risk_nav_title", "risk-disclosure"],
  ["card_terms_title", "card-terms"],
  ["card_framework_title", "card-framework"],
  ["card_use_title", "card-use-terms"],
  ["commercial", "commercial_policy", "commercial_title"],
  ["disclosure_policy", "conflict", "disclosure_policy"],
  ["whistleblowing", "whistleblowing"],
]
