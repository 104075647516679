import AboutEn from "@/Languages/about/en.json"
import AboutLt from "@/Languages/about/lt.json"
import CardFramework from "@/Languages/card-framework.en.json"
import CardFramewordLt from "@/Languages/card-framework.lt.json"
import CardTerms from "@/Languages/card-terms.en.json"
import CardTermsLt from "@/Languages/card-terms.lt.json"
import English from "@/Languages/en.json"
import Litva from "@/Languages/lt.json"

export const LANGUAGES = [
  {
    code: "en-US",
    label: "English",
    messages: { ...English, ...CardFramework, ...CardTerms, ...AboutEn },
  },
  {
    code: "lt-LT",
    label: "Lithuania",
    messages: { ...Litva, ...CardFramewordLt, ...CardTermsLt, ...AboutLt },
  },
]
