import { FormattedMessage } from "react-intl"

export const Msg = ({
  id,
  vars,
  tag,
}: {
  id: string
  vars?: Record<string, any>
  tag?: React.ElementType
}) => {
  return (
    <FormattedMessage
      id={id}
      values={{
        strong: (chunk) => <strong>{chunk}</strong>,
        link: (chunk) => <a href={String(chunk)}>{chunk}</a>,
        a: (chunk) => <a href={String(chunk)}>{chunk}</a>,
        email: (chunk) => <a href={`mailto:${chunk}`}>{chunk}</a>,
        b: (chunk) => <strong>{chunk}</strong>,
        c: (chunk) => <strong>{chunk}</strong>,
        p: (chunk) => <p>{chunk}</p>,
        i: (chunk) => <i>{chunk}</i>,
        ...vars,
      }}
      tagName={tag}
    />
  )
}
