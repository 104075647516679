import { Msg } from "@/Components/Msg"
import { Layout } from "@/Layout"
import { Container } from "@/Layout/Container"
import { lazy, Suspense, useMemo, useState } from "react"
import { Link, Redirect, Route, Switch, useLocation } from "react-router-dom"
import { LEGAL_LINKS } from "./links"
import "./style.css"
import "./style.sass"

const CardFramework = lazy(async () => await import("./Sections/CardFramework"))
const CardTerms = lazy(async () => await import("./Sections/CardTerms"))
const WizarbitTerms = lazy(async () => await import("./Sections/WizarbitTerms"))
const AmlPolicy = lazy(async () => await import("./Sections/Aml"))
const PrivacyPolicy = lazy(async () => await import("./Sections/Privacy"))
const RefundPolicy = lazy(async () => await import("./Sections/Refund"))
const RiskDisclosure = lazy(async () => await import("./Sections/Risk"))
const TermsOfUse = lazy(async () => await import("./Sections/TermsOfUse"))
const Complaints = lazy(async () => await import("./Sections/Complaints"))
const CommercialPolicy = lazy(async () => await import("./Sections/Commercial"))
const DisclosureOfConflict = lazy(async () => await import("./Sections/Disclosure of Conflict"))
const Whistle = lazy(async () => await import("./Sections/Whistleblowing"))

export const Legal = () => {
  const [title, setTitle] = useState(LEGAL_LINKS[0][0])
  const { pathname } = useLocation()
  const currenctPath = useMemo(() => {
    const result = pathname.split("/").pop()
    // eslint-disable-next-line
    const [label, path, exTitle] = LEGAL_LINKS.find(([label, path]) => path === result)
    setTitle(exTitle ?? label)
    return result
  }, [pathname])
  return (
    <Layout mb={70}>
      <section className="legal">
        <Container>
          <div className="legal__wrap">
            <h1 className="legal__title">
              <Msg id={title} />
            </h1>
            <div className="legal__item">
              <div className="legal-nav">
                <h2 className="legal-nav__title">
                  <Msg id="legal_information" />
                </h2>
                <ul className="legal-nav__list">
                  {LEGAL_LINKS.map(([label, path]) => {
                    return (
                      <li className="legal-nav__item">
                        <Link
                          className={`legal-nav__link${path === currenctPath ? " active" : ""}`}
                          to={path}
                        >
                          <Msg id={label} />
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>

              <div className="legal-content">
                <div className="legal-content__block">
                  <Suspense fallback="Loading Legal">
                    <Switch>
                      <Route path="/legal/aml-policy" component={AmlPolicy} />
                      <Route path="/legal/terms-of-use" component={TermsOfUse} />
                      <Route path="/legal/privacy-policy" component={PrivacyPolicy} />
                      <Route path="/legal/refund-policy" component={RefundPolicy} />
                      <Route path="/legal/risk-disclosure" component={RiskDisclosure} />
                      <Route path="/legal/card-terms" component={CardTerms} />
                      <Route path="/legal/card-framework" component={CardFramework} />
                      <Route path="/legal/card-use-terms" component={WizarbitTerms} />
                      <Route path="/legal/complaints" component={Complaints} />
                      <Route path="/legal/commercial_policy" component={CommercialPolicy} />
                      <Route path="/legal/conflict" component={DisclosureOfConflict} />
                      <Route path="/legal/whistleblowing" component={Whistle} />
                      <Route path="/" render={() => <Redirect to="/legal/aml-policy" />} />
                    </Switch>
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  )
}
